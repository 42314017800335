
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import Pagination from "@/components/Pagination.vue";
import { OrderSubStatesEnum, PagedResultOfOrdersViewModel, QueueStatus } from "@/api-client";
import { handleQueries } from "@/utils/handleQueries"
import { ordersGet } from "@/api/orders";
import { formatDate } from "@/utils/formatDate";
import { debounce } from 'vue-debounce'
import { formatName, orderSubStatesFilter } from "@/utils";
import { hasClaim } from "@/utils/claims";

type QueueStatusItem = {
  id: QueueStatus,
  name: string
}

@Component({
  name: "Search",
  components: {
    DialogBox,
    Pagination,
  },
  filters: {
    orderSubStatesFilter: (status:OrderSubStatesEnum) => orderSubStatesFilter(status)
  }
})
export default class extends Vue {
  tableData: PagedResultOfOrdersViewModel = {
    totalCount: 0,
    pageCount: 0,
    pageSize: 0,
    pageNumber: 0,
    data: [],
  };
  filter: string = "";
  queueStatus: QueueStatus | "" = "";
  queueStatusDdl: QueueStatusItem[] = [
    {
      id: "PendingInitialPayment",
      name: "New Orders - Pending Initial Payment"
    },
    {
      id: "PendingTelkomActivation",
      name: "New Orders - Pending Telkom Activation"
    },
    {
      id: "NewlyActivated",
      name: "New Orders - Newly Activated"
    },
    {
      id: "PaymentDue",
      name: "Active Orders - Payment Due"
    },
    {
      id: "PaidUp",
      name: "Active Orders - Paid Up"
    },
    {
      id: "PendingSuspension",
      name: "Suspended Orders - Pending Suspension"
    },
    {
      id: "SuspendTelkomErrors",
      name: "Suspended Orders - Suspend Telkom Errors"
    },
    {
      id: "PendingCancelation",
      name: "Cancelled Orders - Pending Cancelation"
    },
    {
      id: "CancelTelkomErrors",
      name: "Cancelled Orders - Cancel Telkom Errors"
    },
  ];
  pageNumber = 1;
  pageSize = 20;
  userHasPermisson = {
    order: false,
    customer: false
  }
  dFn = debounce((val:Function) => val(), '1200ms')

  get params() {
    return {
      filter: this.filter,
      pageNumber: this.pageNumber.toString(),
      pageSize: this.pageSize.toString(),
      queueStatus: this.queueStatus
    }
  }

  async created() {
    this.userHasPermisson = {
      order: await hasClaim("ViewOrders"),
      customer: await hasClaim("ViewCustomer")
    }

    let toQuery = {
      filter: this.filter,
      queueStatus: this.queueStatus,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize
    }

    this.setQueryWatch(toQuery)

    this.loadInitialData();
  }

  mounted() {
    setTimeout(() => {
      document.getElementById("search")?.focus();
    }, 0);
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {

        if(typeof (this as any)[field] == 'number') {
          (this as any)[field] = +queries[field];
        } else {
          (this as any)[field] = queries[field];
        }
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleDate(date: any) {
    return formatDate(date);
  }

  handleName(name:string) {
    return formatName(name)
  }

  handleFilter() {
    // if(isClear || this.filter.length >= 3) {
      // this.dFn(() => {
        this.pageNumber = 1;
        this.loadInitialData().then(() => {
          setTimeout(() => {
            document.getElementById("search")?.focus();
          }, 0);
        })
      // })
    // }
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  handlePageSizeChange(size: number) {
    this.pageNumber = 1;
    this.pageSize = size;
    this.loadInitialData()
  }

  async loadInitialData() {
    await ordersGet(this.pageNumber, this.pageSize, this.filter || undefined, [], this.queueStatus || undefined)
    .then(async (res) => {
      this.tableData = res;
    })
  }
}
